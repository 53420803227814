import React from 'react';
import { HourGlassLoaderIcon } from '../../assets/icons';

interface Props {
    message?: string;
    submessage1?: string;
    submessage2?: string;
}

const LoadingPage: React.FC<Props> = ({ message, submessage1, submessage2 }) => {
    return (
        <>
            <div className="text flex h-screen flex-col content-center items-center justify-center font-semibold">
                <HourGlassLoaderIcon className="animate-spin-slow" />
                {message && <p className="m-6 text-xl text-secondary-classic">{message}</p>}

                {submessage1 && <p className="m-1 text-lg text-secondary-classic">{submessage1}</p>}
                {submessage2 && <p className="m-1 text-lg text-secondary-classic">{submessage2}</p>}
            </div>
        </>
    );
};

export default LoadingPage;

import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: window.REACT_APP_GTM_CODE || '',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <React.StrictMode>
        <GoogleReCaptchaProvider reCaptchaKey={window.REACT_APP_CAPTCHA_KEY_V3}>
            <QueryClientProvider client={queryClient}>
                <App />
                <ReactQueryDevtools initialIsOpen />
            </QueryClientProvider>
        </GoogleReCaptchaProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

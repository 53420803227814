import { useEffect } from 'react';

const RedirectToBaseUrl = () => {
  useEffect(() => {
    window.location.href = window.BASE_URL;
  }, []);

  return null;
};

export default RedirectToBaseUrl;
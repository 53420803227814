/* eslint-disable max-lines-per-function */
import React, { createContext, useContext, useState } from 'react';

interface IErrorContext {
    onOpen: (errorInfo: { trackingId: string; message: string }) => void;
    onClose: () => void;
    isOpen: boolean;
    message: string;
    trackingId: string;
}

export const ErrorPopUpContext = createContext<IErrorContext>({
    onClose: () => {},
    onOpen: () => {},
    isOpen: false,
    message: '',
    trackingId: '',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ErrorPopupProvider: React.FC = (props: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const [errorInfo, setErrorInfo] = useState({ trackingId: '', message: '' });
    const onOpen = ({ trackingId, message }: { trackingId: string; message: string }) => {
        setIsOpen(true);
        setErrorInfo({ trackingId, message });
    };
    const onClose = () => {
        setIsOpen(false);
        setErrorInfo({ trackingId: '', message: '' });
    };

    return (
        <ErrorPopUpContext.Provider
            value={{
                onClose,
                onOpen,
                isOpen,
                message: errorInfo.message,
                trackingId: errorInfo.trackingId,
            }}
            {...props}
        />
    );
};

export const useErrorPopUpContext = (): IErrorContext => useContext(ErrorPopUpContext);

export default ErrorPopupProvider;

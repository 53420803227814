import React from 'react';
import LoadingPage from '../loading-page/LoadingPage';

interface Props {
    Component: React.LazyExoticComponent<React.FC>;
}

const ReactSuspense = ({ Component }: Props): JSX.Element => {
    return (
        <React.Suspense fallback={<LoadingPage />}>
            <Component />
        </React.Suspense>
    );
};

export default ReactSuspense;

import React from 'react';
import Pages from './pages/Pages';
import 'react-loading-skeleton/dist/skeleton.css';
import ErrorPopupProvider from './context/ErrorPopUp';

const App: React.FC = () => {
    return (
        <div className="relative mx-auto min-h-screen w-full bg-neutrals-0">
            <ErrorPopupProvider>
                <Pages />
            </ErrorPopupProvider>
        </div>
    );
};

export default App;

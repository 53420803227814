import React from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import ErrorPopup from '../components/error-popup/ErrorPopup';
import ReactSuspense from '../components/react-suspense/ReactSuspense';
import { ROUTES_PATH } from '../constants';
import { useErrorPopUpContext } from '../context/ErrorPopUp';
const CustomerJourneyPage = React.lazy(
    () => import('./customer-journey-layout/CustomerJourneyLayout'),
);
const AbandonPage = React.lazy(() => import('./abandon/Abandon'));
const AbandonOTPPage = React.lazy(() => import('./abandon/AbandonOTP'));
import PageNotFound from './pageNotFound/PageNotFound';
import RedirectToBaseUrl from './RedirectToBaseURL';

const Pages: React.FC = () => {
    const { onClose, isOpen, message, trackingId } = useErrorPopUpContext();
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path={`${ROUTES_PATH.ROOT}/:guid`}
                    element={<ReactSuspense Component={CustomerJourneyPage} />}
                />

                <Route
                    path={`${ROUTES_PATH.CREDIT_CARD_ABANDON}`}
                    element={<ReactSuspense Component={AbandonPage} />}
                />
                <Route
                    path={`${ROUTES_PATH.PERSONAL_LOAN_ABANDON}`}
                    element={<ReactSuspense Component={AbandonPage} />}
                />
                <Route
                    path={`${ROUTES_PATH.CREDIT_CARD_ABANDON}/:resumptionSessionId`}
                    element={<ReactSuspense Component={AbandonOTPPage} />}
                />
                <Route
                    path={`${ROUTES_PATH.PERSONAL_LOAN_ABANDON}/:resumptionSessionId`}
                    element={<ReactSuspense Component={AbandonOTPPage} />}
                />

                <Route path="/app" element={<RedirectToBaseUrl/>} />
                <Route
                    path="/creditcard"
                    element={<Navigate replace to={ROUTES_PATH.CREDIT_CARD} />}
                />
                <Route
                    path="/personalloan"
                    element={<Navigate replace to={ROUTES_PATH.PERSONAL_LOAN} />}
                />
                <Route path="*" element={<PageNotFound />} />
            </Routes>

            <ErrorPopup
                isOpen={isOpen}
                onClose={onClose}
                message={message}
                trackingId={trackingId}
            />
        </BrowserRouter>
    );
};

export default Pages;

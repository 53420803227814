import { IProductTypes, IScreens } from '../interfaces';

const IMAGES: Record<string, string> = {
    M$PA: '/MCC_DOLLAR_PLATINUM_MASTERCARD.png',
    MGOA: '/MCC_GOLD_MASTERCARD.png',
    MLHA: '/MCC_MLITE.png',
    MPLA: '/MCC_PLATINUM_MASTERCARD.png',
    MCPB: '/MCC_PSBANK.png',
    MTOA: '/MCC_TOYOTA_MASTERCARD.png',
    MWOA: '/MCC_WORLD_MASTERCARD.png',
    VFSA: '/MCC_FEMME_SIG.png',
    VGOA: '/MCC_GOLD_VISA.png',
    VPAT: '/METROBANK_VISA_PLAT_CARD.png',
    VOGA: '/METROBANK_LANDERS_VISA.png',
};

export const ROUTES_PATH = {
    ROOT: '/app',
    CREDIT_CARD: '/app/creditcard',
    PERSONAL_LOAN: '/app/personalloan',
    CREDIT_CARD_ABANDON: '/creditcard/resumeapplication',
    PERSONAL_LOAN_ABANDON: '/personalloan/resumeapplication',
};

export const REDIRECTION_PATH: Record<IProductTypes, string> = {
    Cards: ROUTES_PATH.CREDIT_CARD,
    PersonalLoan: ROUTES_PATH.PERSONAL_LOAN,
    '': '/',
};

export const EXTERNAL_LINKS = {
    SUPPLEMENTARY_CARD: 'https://metrobankcard.com/cards/apply-supplementary',
    BALANCE_TRANSFER: 'https://metrobankcard.com/cardsservices/balance-transfer',
    CASH_2_GO: 'https://metrobankcard.com/cardsservices/cash-2-go',
    BILLS_2_PAY: 'https://metrobankcard.com/cardsservices/bills-2-pay',
    METRO_BANK_URL: 'https://metrobankcard.com/',
    APPLICATION_DECLARATION: 'https://www.metrobankcard.com/about/ApplicationDeclaration',
    TERMS_AND_CONDITION: 'https://www.metrobankcard.com/About/TermsAndCondition',
    DATA_PRIVACY_POLICY: 'https://www.metrobankcard.com/about/privacy',
    ABANDON: '/abandon',
};

const CONSTANTS = {
    LOV_MAPPING: {
        AREA_CODE: 'area-code',
        BARANGAY: 'barangay',
        CITY: 'city',
        CIVIL_STATUS: 'civil-status',
        COUNTRY: 'country',
        DEFAULT: 'DEFAULT',
        EDUCATION: 'education',
        EMAIL_OPTION: 'email-option',
        EMPLOYMENT_STATUS: 'employment-status',
        GENDER: 'gender',
        HOME_OWNERSHIP: 'home-ownership',
        ID_TYPE: 'Id',
        NATIONALITY: 'nationality',
        NATURE_OF_BUSINESS: 'nature-of-business',
        OCCUPATION: 'occupation',
        OFI_CARD_NAME: 'ofi-card-name',
        PROVINCE: 'province',
        SOURCE_OF_FUND: 'source-of-fund',
        STATEMENT_EMAIL_OPTION: 'statement-mail-option',
        ZIP: 'zip',
        PROOF_OF_ID: 'proof-of-id',
        PROOF_OF_INCOME: 'proof-of-income',
        PREFERRED_DELIVERY_ADDRESS: 'preferred-delivery-address',
        LOAN_PURPOSE: 'loan-purpose',
        LOAN_TERM: 'loan-term-in-months'
    },
    IMAGES,
    PHONE_NUMBER: '88-700-700',
    PHONE_NUMBER_WITH_EXTENSION: '(02) 88-700-700',
    PL_PRODUCT_CODE: 'PL4A',
};

export const PAGE_HEADINGS: Record<IScreens, string> = {
    LOADING_PAGE: '',
    KYE_STEP: 'Know Your Eligibility',
    PERSONAL_LOAN_CALCULATOR_STEP: 'Loan Details',
    BASIC_DETAILS_STEP: 'Basic Details',
    TU_PREFILL_CONSENT_STEP: 'Prefill Option',
    TU_PREFILL_OTP_VERIFICATION_STEP: 'Verify your Mobile Number',
    PERSONAL_DETAILS_STEP: 'Personal Details',
    EMPLOYMENT_DETAILS_STEP: 'Employment Details',
    REVIEW_RECORDS_STEP: 'Review Your Records',
    APPLICATION_DETAILS_STEP: 'Additional Information',
    VERIFY_IDENTITY_HOME: 'Identity Verification',
    DOCUMENT_UPLOAD_STEP_ID_PROOF: 'Identity Verification',
    DOCUMENT_UPLOAD_STEP_INCOME_PROOF: 'Proof of Income',
    LOADING_SCREEN: '',
    Approved: 'Online Reference #ORN_NUMBER',
    THANK_YOU_FAILURE: 'Online Reference #ORN_NUMBER',
    BASIC_DETAILS_STEP_READ_ONLY: 'Basic Details',
    PERSONAL_DETAILS_STEP_READ_ONLY: 'Personal Details',
    EMPLOYMENT_DETAILS_STEP_READ_ONLY: 'Employment Details',
    RESUMPTION_OTP_VERIFICATION_STEP: 'Verify Account'
};


export const LOADING_SCREEN = {
    MESSAGE: 'LOADING',
    SUB_MESSAGE1: 'Please wait.',
    SUB_MESSAGE2: 'Do not refresh or close your browser.',
};

export const INCORRECT_MESSAGE_PROMPT = {
    CC: 'Please enter the 6-digit One-Time Pin we sent to your mobile number so we can proceed with pre filling your credit card application.',
    PL: 'Please enter the 6-digit One-Time Pin we sent to your mobile number so we can proceed with pre-filling your personal loan application.'
}

export const allowedExtensions = ['.jpg', '.jpeg', '.png', '.bmp', '.gif', '.tiff'];

export default CONSTANTS;

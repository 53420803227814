/* eslint-disable max-lines-per-function */
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CloseIcon } from '../../assets/icons';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    trackingId?: string;
    message?: string;
}

const ErrorPopup: React.FC<Props> = ({ isOpen, onClose, trackingId, message }) => {
    return (
        <div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto " onClose={onClose}>
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-neutrals-400 opacity-50" />
                        </Transition.Child>
                        <span className="inline-block h-screen align-middle" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md transform overflow-hidden rounded-lg  bg-neutrals-white text-left align-middle transition-all">
                                <div className=" flex flex-col border-2 border-b-state-error bg-state-error/30 p-6">
                                    <CloseIcon
                                        onClick={onClose}
                                        className=" absolute right-3 top-3 cursor-pointer text-grey-150"
                                    />
                                    <p className=" inline-flex flex-grow items-center justify-start font-semibold">
                                        Something went wrong, please try after sometime{' '}
                                    </p>
                                    {trackingId && window.REACT_APP_SHOW_TRACKING_ID && (
                                        <code className="text-sm font-semibold">
                                            Tracking Id :{' '}
                                            <span className=" font-normal">{trackingId}</span>{' '}
                                        </code>
                                    )}
                                    {message && window.REACT_APP_SHOW_ERROR_MESSAGE && (
                                        <code className="text-sm font-semibold">
                                            Message :{' '}
                                            <span className=" font-normal"> {message}</span>{' '}
                                        </code>
                                    )}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default ErrorPopup;

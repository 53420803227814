import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound: React.FC = () => {
    return (
        <div className="-mt-16 flex h-screen flex-col items-center justify-center">
            <h1 className="text-5xl font-bold text-primary">404 Not Found</h1>
            <Link to={'/'} className="cursor-pointer underline">
                Go Back
            </Link>
        </div>
    );
};

export default PageNotFound;
